import { Grid } from '@mui/material';
import Image from 'next/image';
import { memo } from 'react';

import useBreakpoints from '../../Hooks/useBreakpoints';

const DevyxResolve: React.FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      xs={12}
      gap={'16px'}
      position={'relative'}
    >
      <Grid
        item
        width={{ xs: '96px', lg: '168px' }}
        height={{ xs: '86px', lg: '150px' }}
        left={'161px'}
        position={{ xs: 'unset', lg: 'absolute' }}
      >
        <Image
          src="/img/welcome-check.webp"
          alt="check"
          width={isDesktop ? '168px' : '96px'}
          height={isDesktop ? '150px' : '86px'}
        />
      </Grid>
      <Grid
        item
        className={'barlow--size32 bold'}
        style={{ color: 'var(--primary-green)' }}
      >
        O que a Devyx resolve?
      </Grid>
      <Grid item className="open-sans--size18">
        Veja algumas das principais soluções para você e seus clientes.
      </Grid>
    </Grid>
  );
};
export default memo(DevyxResolve);
